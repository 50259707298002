import React from "react"
import { graphql } from "gatsby"
import BannerHeader from "pages/common/BannerHeader"
import GetStart from "pages/common/GetStart"
import ContainerMain from "src/pages/common/ContainerMain"
import FilterJobs from "src/pages/company/Career/components/FilterJobs"
import Layout from "src/pages/common/layout"
import Head from "src/pages/common/Head"
import { StaticImage } from "gatsby-plugin-image"
import { useFormatter } from "helpers/i18n"
import "./JobDetail.scss"

export default function Component({ data }) {
  return (
    <>
      <Head
        showCaptcha={true}
        image={
          "https://s3.ap-southeast-1.amazonaws.com/public.onpoint.vn/clients-partner-meta.png"
        }
      />
      <Layout>
        <JobDetail data={data} />
      </Layout>
    </>
  )
}
function JobDetail({ data }) {
  const { __ } = useFormatter()
  const job = data.job
  const allJobs = data.allJob?.nodes
  const jobDescriptions = job?.job_descriptions
  const jobRequirements = job?.job_requirements
  const banner = "../../images/Company/Career/jobDetailBanner.png"
  const bannerMobile =
    "../../images/Company/Career/job_detail_banner_mobile.png"
  return (
    <>
      <div className="job-detail-page">
        <BannerHeader
          breadcrumb={[
            {
              label: `${__({ defaultMessage: "Home/" })}`,
              url: "/",
            },
            {
              label: `${__({ defaultMessage: "Company/" })}`,
            },
            {
              label: `${__({ defaultMessage: "Careers/" })}`,
              url: "/company-career",
            },
            { label: job?.name },
          ]}
          banner={
            <StaticImage
              layout="fullWidth"
              src={banner}
              alt=""
              decoding="async"
              fetchpriority="high"
            />
          }
          mobileBanner={
            <StaticImage
              layout="fullWidth"
              src={bannerMobile}
              alt=""
              decoding="async"
              fetchpriority="high"
            />
          }
          logo={""}
          description={""}
        />
        <ContainerMain>
          <div className="job-detail-container">
            <div className="job-detail-header">
              <div className="title">
                <div className="title-wrap">
                  <div className="section-title title-text">{job?.name}</div>
                  <div className="title-deadline">{job?.deadline}</div>
                </div>
              </div>
            </div>
            <div className="job-description-container">
              <div className="job-description">
                <p className="header">
                  {__({
                    defaultMessage: "Job Description",
                  })}
                </p>
                {jobDescriptions?.map((description, index) => {
                  return (
                    <div className="description" key={index}>
                      <p>{description.report_to}</p>
                      <ul>
                        {description.details?.map((i, idx) => {
                          return <li key={`detail_${idx}`}>{i}</li>
                        })}
                      </ul>
                    </div>
                  )
                })}
              </div>
              <div className="job-description">
                <p className="header">
                  {__({
                    defaultMessage: "Job Requirements",
                  })}
                </p>
                {jobRequirements?.map((requirement, index) => {
                  return (
                    <div className="description" key={index}>
                      <p>{requirement.level}</p>
                      <ul>
                        {requirement.details?.map((i, idx) => {
                          return <li key={`detail_${idx}`}>{i}</li>
                        })}
                      </ul>
                    </div>
                  )
                })}
              </div>
              <div className="job-description">
                <p className="header">
                  {__({
                    defaultMessage: "Job Contact",
                  })}
                </p>
                <div className="description">
                  <p>
                    Has this role caught your interest? Reach out to{" "}
                    <a href="mailto:hr@onpoint.vn">hr@onpoint.vn</a> for more
                    details or follow{" "}
                    <a
                      href="https://www.linkedin.com/company/onpointvn"
                      target="_blank"
                    >
                      www.linkedin.com/company/onpointvn/
                    </a>{" "}
                    for more career opportunities! 
                  </p>
                </div>
              </div>
            </div>

            <div className="all-job">
              <FilterJobs data={allJobs} is_tech={job?.is_tech} />
            </div>
          </div>
        </ContainerMain>
      </div>
      <GetStart productName={"Job Openning"} />
    </>
  )
}

export const query = graphql`
  query ($id: String!) {
    job(id: { eq: $id }) {
      is_tech
      name
      deadline
      level
      job_descriptions {
        report_to
        details
      }
      job_requirements {
        level
        details
      }
    }
    allJob {
      nodes {
        is_tech
        level
        name
        deadline
        pathJob: gatsbyPath(filePath: "/jobs/{job.name}")
      }
    }
  }
`
